import { IsDefined } from '@utils/js-ts';
const isUserPortfolioOwner = (portfolio, user) => {
    if (!IsDefined(portfolio) || !IsDefined(user)) {
        return false;
    }
    return portfolio.profileId === user.activeProfileId ||
        portfolio.profileId === user.unactiveProfileId;
};
const isUserSubsbribedToPortfolio = (portfolio, user) => {
    if (!IsDefined(portfolio) || !IsDefined(user)) {
        return false;
    }
    return !!portfolio.user_subscription_summary;
};
const mapCurrentUserPortfolio = (portfolio) => {
    var _a;
    return (Object.assign(Object.assign({}, portfolio), { logo: ((_a = portfolio.profile.profilePicture) === null || _a === void 0 ? void 0 : _a.avatar) || '' }));
};
export { isUserPortfolioOwner, isUserSubsbribedToPortfolio, mapCurrentUserPortfolio, };
